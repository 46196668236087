import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import React from "react";

import '../../../../css/sites/BlogMarkdown.css'


// Terms of Use and Privacy Policy

export class MarkdownLoader extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            text: ''
        }
    }

    componentDidMount() {
        fetch(this.props.input_file).then(res => res.text()).then(text => this.setState({'text': text}))
    }

    render() {

        return (
            <div style={{width: '85%', 'padding-left': '5%', height: (this.props.visible) ? '100%' :'2vh', overflow: 'auto'}}>
                <Markdown
                    className='markdown'
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        code({ node, inline, className, children, ...props }: any) {
                            const match = /language-(\w+)/.exec(className || '');

                            return !inline && match ? (
                                <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        },
                    }}
                >{this.state.text}</Markdown>
            </div>
        )
    }
}

export default MarkdownLoader;