import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../css/sites/Research.css'

import {
    faChevronUp,
    faChevronDown
} from "@fortawesome/fontawesome-free-solid";

interface IProps {
    open?: boolean;
    title: string;
}


const Collapsible: React.FC<IProps> = ({open, children, title, styling, screen_size}) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleFilterOpening = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <div className="card">
                <div>
                    <div className="p-5 border-bottom d-flex justify-content-between">
                        <table>
                            <tr>
                                <td className={'td_research_items'}>
                                    <h6 className={'default_text_div_' + screen_size + " collapsible_header_" + styling }>{title}</h6>
                                </td>
                                <td>
                                    <button type="button" className="show_publication_info_button"
                                            onClick={handleFilterOpening}>
                                        {!isOpen ? (
                                            <FontAwesomeIcon icon={faChevronDown}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faChevronUp}/>
                                        )}
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="border-bottom">
                    <div>{isOpen && <div className="p-3">{children}</div>}</div>
                </div>
            </div>
        </>
    );
};

export default Collapsible;