import Page from "../Page";
import ResearchItem from "./page_elements/publications/ResearchItem";

import masters_thesis from '../../../assets/Master_Thesis_Florian_Frank.pdf'
import bachelors_thesis from '../../../assets/Bachelor_Thesis_Florian_Frank.pdf'

import bibtex_2023_sec_process_automotive from '../../../assets/bibtex/2023_sec_process_automotive.bib'
import bibtex_2023_cnt_puf_mdpi_journal from '../../../assets/bibtex/2023_cnt_puf_mdpi_journal.bib'
import bibtex_2023_cnt_puf_ieee_nano from '../../../assets/bibtex/2023_CNT_PUF_IEEE_NANO.bib'
import bibtex_2023_dram_spying_ieee_tifs from '../../../assets/bibtex/2023_dram_spying_ieee_tifs.bib'
import bibtex_2023_memristor_puf_esorics from '../../../assets/bibtex/2022_memristor_puf_esorics.bib'
import bibtex_2022_switch_matrix_mocast from '../../../assets/bibtex/2022_switch_matrix_paper_mocast.bib'
import bibtex2022_nand_pufs_icce_berlin from '../../../assets/bibtex/2023_nand_pufs_ICCE_Berlin.bib'
import bibtex2022_real_world_chaos from '../../../assets/bibtex/2022_real_world_chaos.bib'
import bibtex_2024_asiaccs from '../../../assets/bibtex/2024_data_binding_asiaccs.bib'


import "../../css/sites/Research.css"
import React from "react";

export class Research extends Page {

    constructor(props) {
        super(props);
        window.research = this;

        this.state = {
            styling: props.styling
        }
    }

    render() {
        return (
            <div className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={'main_content_div_' + this.state.screen_size}>
                    <h2 className={"heading1_" + this.state.styling}>Research</h2>
                    <div className={"subdiv_" + this.state.styling}>
                        <div className="research_table_div">
                            <table className="research_table">

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling}>2024</th>
                                </tr>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>Secure Data-Binding in FPGA-based Hardware Architectures
                                                  utilizing PUFs<br/>
                                                  (F. Frank, M. Schmid, F. Klement P. Palani, A. Weber, E. Kavun, W.
                                                  Xiong, T. Arul and S. Katzenbeisser)<br/> in 2024 ASIACCS: ACM Asia Conference on Computer and Communications Security
                                              </div>}
                                              authors="Florian Frank, Martin Schmid, Felix Klement Purushothaman Palani, Andreas Weber, Elif Kavun, Wenjie
                                                  Xiong, Tolga Arul and Stefan Katzenbeisser"
                                              venue="2024 ACM Asia Conference on Computer and Communications Security (ASIACCS)"
                                              venue_location=" PARKROYAL on Beach Road Hotel, Singapore"
                                              doi="https://doi.org/10.1145/3634737.3656996"
                                              bibtex={bibtex_2024_asiaccs}/>
                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling}>2023</th>
                                </tr>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>A Security Process for the Automotive Service-Oriented
                                                  Software Architecture<br/>
                                                  (D. Püllen, F. Frank, M. Christl, W. Liu and S. Katzenbeisser)<br/>in
                                                  IEEE Transactions
                                                  on Vehicular Technology (Early Access)</div>}
                                              authors="Dominik P&uuml;llen, Florian Frank, Marion Christl, Wuhao Liu, Stefan Katzenbeisser"
                                              journal="IEEE Transactions on Vehicular Technology"
                                              pages="1-16"
                                              doi="https://doi.org/10.1109/TVT.2023.3333397"
                                              bibtex={bibtex_2023_sec_process_automotive}/>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>CNT-PUFs: Highly Robust and Heat-Tolerant
                                                  Carbon-Nanotube-Based
                                                  Physical Unclonable Functions. Nanomaterials<br/>
                                                  (Frank, F., Böttger, S., Mexis, N., Anagnostopoulos, N.A., Mohamed,
                                                  A., Hartmann, M., Kuhn, H., Helke, C., Arul, T., Katzenbeisser, S. and
                                                  Hermann)<br/>
                                                  in MDPI Nanomaterials 2023, 13(22), 2930</div>}
                                              authors="Florian Frank, Simon Böttger, Nico Mexis, Nikolaos Athanasios Anagnostopoulos, Ali Mohamed,
                                              Martin Hartmann, Harald Kuhn, Christian Helke, Tolga Arul, Stefan Katzenbeisser, Sascha Hermann"
                                              journal="Nanomaterials"
                                              volume="13"
                                              number="22"
                                              article_number="2930"
                                              issn="2079-4991"
                                              pages="" doi="https://doi.org/10.3390/nano13222930"
                                              bibtex={bibtex_2023_cnt_puf_mdpi_journal}/>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>CNT-PUFs: Highly robust Physical Unclonable
                                                  Functions based on Carbon Nanotubes<br/>
                                                  (Böttger, S., Frank, F., Anagnostopoulos, N. A., Mohamed, A.,
                                                  Hartmann, M., Arul, T., ...
                                                  & Katzenbeisser, S.)<br/>in 2023 IEEE 23rd International Conference on
                                                  Nanotechnology (NANO)</div>}
                                              authors="Simon Böttger, Florian Frank, Nikolaos Athanasios Anagnostopoulos,
                                              Ali Mohamed, Martin Hartmann, Tolga Arul, Sascha Hermann, Stefan Katzenbeisser"
                                              venue="2023 IEEE 23rd International Conference on Nanotechnology (NANO)"
                                              venue_location="Ramada Plaza by Wyndham Jeju Ocean Front, Jeju-do, South Korea"
                                              pages="1-6"
                                              doi="https://doi.org/10.1109/NANO58406.2023.10231160"
                                              bibtex={bibtex_2023_cnt_puf_ieee_nano}/>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>Abusing Commodity DRAMs in IoT Devices to Remotely Spy on
                                                  Temperature<br/>
                                                  (Frank, F., Xiong, W., Anagnostopoulos, N. A., Schaller, A., Arul, T.,
                                                  Koushanfar, F., ... & Szefer, J.)<br/>in IEEE Transactions on
                                                  Information Forensics and Security ( Volume: 18)</div>}
                                              authors="Florian Frank, Wenjie Xiong, Nikolaos Athanasios Anagnostopoulos,
                                              André Schaller, Tolga Arul, Farinaz Koushanfar, Stefan Katzenbeisser, Ulrich Rührmair,
                                              Jakub Szefer"
                                              journal="IEEE Transactions on Information Forensics and Security."
                                              volume="18"
                                              pages="2991-3005"
                                              doi="https://doi.org/10.1109/TIFS.2023.3271252"
                                              bibtex={bibtex_2023_dram_spying_ieee_tifs}/>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling}>2022</th>
                                </tr>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>Using Memristor Arrays as Physical Unclonable Functions,<br/>
                                                  (Frank, F., Arul, T., Anagnostopoulos, N.A. and Katzenbeisser,
                                                  S.)<br/>
                                                  in 27th European Symposium on Research in Computer Security (ESORICS)
                                              </div>}
                                              authors="Florian Frank, Tolga Arul, Stefan Katzenbeisser"
                                              venue="European Symposium on Research in Computer Security (ESORICS2022)"
                                              booktitle="Computer Security -- ESORICS 2022"
                                              publisher="Springer, Cham"
                                              pages="250-272"
                                              doi="https://doi.org/10.1007/978-3-031-17143-7_13"
                                              bibtex={bibtex_2023_memristor_puf_esorics}
                                              issb='978-3-031-17143-7'/>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>Testing Physical Unclonable Functions Implemented on
                                                  Commercial Off-the-Shelf NAND Flash Memories Using Programming
                                                  Disturbances
                                                  <br/>(Anagnostopoulos NA, Fan Y, Saleem MU, Mexis N, Gelóczi E,
                                                  Klement F, Frank F, Schaller A, Arul T, Katzenbeisser S.)<br/>in 2022
                                                  IEEE 12th International Conference on Consumer Electronics
                                                  (ICCE-Berlin)</div>}
                                              authors="Anagnostopoulos, Nikolaos Athanasios and Fan, Yufan and Saleem, Muhammad Umair and Mexis, Nico and Gelóczi, Emiliia and Klement, Felix and Frank, Florian and Schaller, André and Arul, Tolga and Katzenbeisser, Stefan"
                                              venue="2022 IEEE 12th International Conference on Consumer Electronics (ICCE-Berlin)"
                                              pages="1-9"
                                              doi="https://doi.org/10.1109/ICCE-Berlin56473.2022.10021310"
                                              bibtex={bibtex2022_nand_pufs_icce_berlin}/>


                                <ResearchItem styling={this.state.styling}
                                              screen_size={this.state.screen_size} title={<div>A Dedicated Mixed-Signal
                                    Characterisation and Testing Framework for Novel Digital Security Circuits
                                    That Use Carbon-Nanotube-Based Physical Unclonable Functions<br/>
                                    (Frank, F., Anagnostopoulos, N.A., Böttger, S., Hermann, S., Arul, T.,
                                    Stavrinides, S.G. and Katzenbeisser, S.)<br/>in 2022 11th International Conference
                                    on Modern Circuits and Systems Technologies (MOCAST)</div>}
                                              authors="Florian Frank, Nikolaos Athanasios Anagnostopoulos,
                                                    Simon Böttger, Sascha Hermann, Tolga Arul,
                                                    Stavros G Stavrinides, Stefan Katzenbeisser"
                                              venue="International Conference on Modern Circuits and Systems Technologies
                                                    (MOCAST)"
                                              venue_location="Bremen, Germany"
                                              pages="1-4"
                                              doi="https://doi.org/10.1109/MOCAST54814.2022.9837567"
                                              bibtex={bibtex_2022_switch_matrix_mocast}/>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling}>Non-peer reviewed works
                                    </th>
                                </tr>

                                <ResearchItem styling={this.state.styling} screen_size={this.state.screen_size}
                                              title={<div>Real-World Chaos-Based Cryptography
                                                  Using Synchronised Chua Chaotic Circuits<br/>(Geloczi, E.,
                                                  Anagnostopoulos, N.A., Stavrinides, S.G., Mexis, N., Frank,
                                                  F., Arul, T. and Katzenbeisser, S.)</div>}
                                              authors="Emiliia Nazarenko, Nikolaos Athanasios Anagnostopoulos,
                                                Stavros G. Stavrinides, Nico Mexis, Florian Frank,
                                                Tolga Arul, Stefan Katzenbeisser"
                                              journal="arXiv preprint 10.36227/techrxiv.21030466"
                                              doi="https://doi.org/10.48550/arXiv.2210.11299"
                                              bibtex={bibtex2022_real_world_chaos}/>

                                <tr>
                                    <th className={'heading2 heading2_' + this.state.styling}>Thesis</th>
                                </tr>


                                <ResearchItem styling={this.state.styling}
                                              screen_size={this.state.screen_size} title="Master's Thesis: Efficient Authentication of Service-Oriented
                                              Communication in Autonomous Vehicles"
                                              authors="Florian Frank"
                                              venue=""
                                              venue_location=""
                                              pages=""
                                              file={masters_thesis}
                                              file_name={"Master's Thesis Florian Frank"}/>

                                <ResearchItem styling={this.state.styling}
                                              screen_size={this.state.screen_size} title="Bachelor's Thesis: Portierung der Sensorbibliothek MEDAQLib vonWindows nach Linux mit Entwicklung einer Applikation zum
                                                                                         universellen Testen einzelner Sensoren - Hochschule München"
                                              authors="Florian Frank in cooperation with Micro-Epsilon Messtechnik GmbH & Co. KG"
                                              venue=""
                                              venue_location=""
                                              pages=""
                                              file={bachelors_thesis}
                                              file_name={"Bachelor's Thesis Florian Frank"}/>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Research


