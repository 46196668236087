import React from 'react';
import '../css/Button.css'

class Button extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            id: props.inner_id,
            onClick: props.onClick,
        }
    }

    render() {
        return (
            <React.Fragment>
                <button onClick={this.state.onClick} className={'button_main ' + this.props.button_style}
                        id={this.state.id}>{this.state.value}</button>
            </React.Fragment>);
    }
}

export default Button;