import React, {Component} from 'react'
import '../css/Background.css'

export class Background extends Component {

    constructor(props) {
        super(props)
        window.bg_component = this;

        this.state = {
            header1: props.header1,
            header2: props.header2,
            content: props.content,
        }

        this.componentRef = React.createRef();
    }

    change_content(ct) {
        this.setState({content: ct})
    }

    render() {
        return (
            <React.Fragment>
                <div className="main_div" >
                </div>
                <div className='background_cover' id={"background-cover-"+this.props.styling}>
                </div>
                <div className={'content-frame-'+this.props.styling}>
                    {this.state.content}
                </div>
            </React.Fragment>
        )
    }
}

export default Background
