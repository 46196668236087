import React from 'react';
import '../../css/sites/Imprint.css'

import CloseButton from "../CloseButton";

import sitemap from '../../../assets/sitemap.xml'


/**
 * React component showing the Imprint information of this website.
 */
class Imprint extends React.Component {

    /**
     * Is triggered by an onClick event of the imprint exit button.
     * Removes the visibility flag of this React Component.
     */
    hide_imprint_component() {
        document.getElementsByClassName("imprint_div")[0].classList.add('invisible')
        document.getElementsByClassName("imprint_div")[0].classList.remove('visible')
        document.getElementsByClassName("imprint_div")[0].classList.toggle("animate");
    }

    /**
     * Renders the imprint component.
     * @returns {JSX.Element}
     */
    render() {
        return (
            <React.Fragment>
                <div className={"imprint_div imprint_div_" + this.props.screen_size}>
                    <div className="imprint_inner_div">
                        <div className='close_button_div_imprint' onClick={
                            () => {
                                this.hide_imprint_component()
                            }
                        }>
                            <CloseButton styling={this.props.styling}/>
                        </div>
                        <h1>Imprint</h1>
                        <b>Information according to § 5 TMG and responsible for the content according to § 55 Abs. 2
                            RStV:</b>
                        <br/>
                        Florian Frank, Senefelderstr. 10, 94036 Passau
                        <h3>Contact</h3>
                        E-Mail: info@florian-frank.com
                        <h3>Content of the Website</h3>
                        <div id="content_div">
                            Florian Frank (the website operator) regularly checks and
                            updates the information on his websites. Despite all care,
                            the website operator assumes no liability or guarantee for
                            the timeliness, accuracy, completeness or quality of the
                            information available online. All offers are subject to change
                            and non-binding. The website operator expressly reserves the
                            right to change, supplement or delete individual pages or the
                            entire online offer without prior notice or to discontinue the
                            offer for a specific period of time or permanently.
                        </div>
                        <h3>References and Links</h3>
                        <div id="content_div">
                            Florian Frank is not liable for the content
                            of external websites to which reference is
                            made in the online offer of Florian Frank,
                            e.g. by means of hyperlinks. This also applies to
                            all entries made by third parties in guest books,
                            discussion forums, pin boards and mailing lists set
                            up by the website operator.
                        </div>
                        <h3>Copyright and trademark law</h3>
                        <div id="content_div">
                            The content and structure of the Florian Frank
                            website are protected by copyright.
                            The reproduction of graphics, sound documents,
                            images, film and video clips and texts in other
                            electronic or printed media and publications is
                            not permitted without the express permission of Florian Frank.
                        </div>
                        <h3>Google search</h3>
                        <div id="content_div">
                            This page is search optimized using a <a href={sitemap}>sitemap</a>.
                        </div>

                    </div>
                </div>
            </React.Fragment>);
    }
}

export default Imprint;