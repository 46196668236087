import React from "react";

import profile_picture from '../../../../assets/asia_ccs_speaker.jpg'
import '../../../css/Main.css'

class Introduction extends React.Component {

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'instant'});
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.screen_size === 'large' || this.props.orientation === 'horizontal') &&
                    <React.Fragment>
                        <h3 className={'introduction_heading_horizontal heading2_' + this.props.styling + ' heading2_'+this.props.screen_size}>Hello my name
                            is Florian Frank</h3>
                        <br/>
                    </React.Fragment>
                }

                <div className={'start_page_div_container_' + this.props.screen_size + "_" + this.props.orientation}>

                    <div
                        className={'div_profile_picture-first-page_' + this.props.screen_size + (this.props.screen_size !== 'large' ?
                            ' div_profile_picture-first-page_' + this.props.screen_size + '_' + this.props.orientation : '')}>
                        <img className={"profile_picture-first-page_" + this.props.screen_size +
                            (this.props.screen_size !== 'large' ?
                                ' profile_picture-first-page_' + this.props.screen_size + '_' + this.props.orientation : '')}
                             src={profile_picture} alt=''/>
                    </div>
                    <div
                        className={'introduction_div_' + this.props.styling + " introduction_div introduction_div_" +
                            this.props.screen_size}>
                        {(this.props.screen_size !== 'large' && this.props.orientation === 'vertical') &&
                            <React.Fragment>
                                <h3 className={'heading2_' + this.props.styling}>Hello my name is Florian Frank</h3>
                                <br/>
                            </React.Fragment>
                        }
                        <divg
                            className={'self_description_div self-description-div_' + this.props.styling + ' self_description_div_' + this.props.screen_size}>
                            <h3 className={'h3_'+ this.props.screen_size}> Research and Career</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                I'm currently working as a PhD student at the University of Passau in Germany.
                                My research interests are in the domain of IT-Security and Hardware security.
                                Currently I'm focusing on the development of hardware trust anchors within
                                different types of non-volatile memories. Especially FRAM (Ferroelectric Random Access
                                Memory),
                                MRAM (Magnetoresistance Random Access Memory) and ReRAM (Resistive Random Access
                                Memory).
                                I also investigate the internal characteristics of low-level digital and analog elements
                                like memristors or carbon nanotube field-effect transistors. But I'm also interested in
                                other domains of computer science and open source projects. My favorite programming
                                language
                                is C++.
                            </div>

                            <h3 className={'h3_'+ this.props.screen_size}>Other Activities</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                I enjoy mountain biking in the beautiful surroundings of Passau. During the summer,
                                I frequently go hiking in the Alps, while winter is the perfect
                                season for skiing. In addition to my outdoor pursuits, I have a passion for photography
                                and love capturing memorable moments during my travels. Furthermore, I am owner of a
                                private
                                pilot license and regularly take to the skies, flying from Vilshofen (EDMV).
                            </div>

                            <h3 className={'h3_'+ this.props.screen_size}>Contact</h3>
                            <div className={'default_text_div_' + this.props.screen_size}>
                                Please feel free to contact me by sending a mail to Florian.Frank(at)uni-passau.de.
                            </div>
                        </divg>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Introduction;