import React from 'react';
import Page from "../../../Page";
import Button from "../../../Button";
import CloseButton from "../../../CloseButton";

import '../../../../css/Main.css'
import '../../../../css/ContactForm.css'
import '../../../../css/Button.css'
import emailjs from '@emailjs/browser';


class SendThesisRequest extends Page {
    constructor(props) {
        super(props)
        this.state = {
            topic_name: props.topic_name,
            id: props.id,
            onClick: props.onClick
        }
    }

    exit_page() {
        this.toggle_single_element("content_form_div", "animate", 'className')
        this.add_single_element("content_form_div", "invisible", 'className')
        this.remove_single_element("content_form_div", (this.props.mobile) ?
            'visible_mobile' : 'visible', 'className')
    }

    render() {
        return (
            <div className="content_form_div" id={'content_form_color_' + this.props.styling}>
                <div className={this.props.mobile ? "contact_form_inner_div_mobile" : "contact_form_inner_div"}>
                    <div className={'close_button_div close_button_div_'+this.state.screen_size} onClick={
                        () => {
                            this.exit_page();
                        }
                    }>
                        <CloseButton styling={this.props.styling}/>
                    </div>
                    <h2 className={'heading1_' + this.props.styling}>Contact Form</h2>
                    <table
                        className={(this.state.screen_size === 'mobile' || this.state.screen_size === 'small') ? 'thesis_request_table_mobile' : 'thesis_request_table'}>
                        <tr>
                            <td className={(this.state.screen_size === 'mobile' || this.state.screen_size === 'small') ? 'thesis_request_td_mobile' : 'thesis_request_td'}>
                                <div
                                    className={'contact_form_label_' + this.state.screen_size + ' contact_form_label_' + this.props.styling}>Topic
                                </div>
                            </td>
                            <td>
                                <input id='topic_input' className='input_field' value={this.props.topic_name}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={(this.state.screen_size === 'mobile' || this.state.screen_size === 'small') ? 'thesis_request_td_mobile' : 'thesis_request_td'}>
                                <div className={'contact_form_label_' + this.state.screen_size + ' contact_form_label_' + this.props.styling}>E-Mail</div>
                            </td>
                            <td>
                                <input id='email_input' className='input_field'/>
                            </td>
                        </tr>
                        <tr>
                            <td className={(this.state.screen_size === 'mobile' || this.state.screen_size === 'small') ? 'thesis_request_td_mobile' : 'thesis_request_td'}>
                                <div className={'contact_form_label_' + this.state.screen_size + ' contact_form_label_' + this.props.styling}>Name</div>
                            </td>
                            <td>
                                <input id='name_input' className='input_field'/>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <div className={'contact_form_label_' + this.state.screen_size + ' contact_form_label_' + this.props.styling}>Message</div>
                            </td>
                            <td className={this.props.mobile ? 'thesis_request_td_mobile' : 'thesis_request_td'}>
                                <textarea id='additional_text_input' className='input_area'/></td>
                        </tr>
                    </table>
                    <Button id={(this.state.screen_size === 'mobile' || this.state.screen_size === 'small')
                        ? 'send_button_mobile' : 'send_button'}
                            button_style={'button_' + this.props.styling + ' button_' + this.state.screen_size}
                            value='Send Message' onClick={() => {
                        var templateParams = {
                            send_to: 'Florian.Frank@uni-passau.de',
                            from_name: document.getElementById('name_input').value,
                            reply_to: document.getElementById('email_input').value,
                            topic_name: document.getElementById('topic_input').value,
                            message: document.getElementById('additional_text_input').value,
                        }


                        emailjs.send('service_85z1jvd', 'forward_uni_passau', templateParams, 'oxWzKYYz-YBqVVL8n')
                            .then((result) => {
                                    alert("Message Sent, You will receive an answer shortly");
                                },
                                (error) => {
                                    alert("An error occurred, Please try again", error.text);
                                });

                        this.exit_page();
                    }}/>
                </div>
            </div>);
    }
}

//  this.props.styling
export default SendThesisRequest;