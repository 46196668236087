
class BlogEntry{
    constructor(texts, figure_urls, captions) {
        this.texts = texts
        this.figure_urls = figure_urls
        this.captions = captions
    }

    get_texts(index){
        return this.texts[index]
    }

    get_figure_captions(){
        return [this.figure_urls, this.captions]
    }
}
export default BlogEntry;
