import React from 'react'
import {pixel_width_mobile} from '../../../definitions'
import '../../css/Header.css'
import ReactDOM from 'react-dom'

import Page from "../Page";
import Button from '../Button'
import SideBar from './SideBar'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleHalfStroke} from '@fortawesome/free-solid-svg-icons'
import {faMoon} from '@fortawesome/free-solid-svg-icons'
import {faSun} from '@fortawesome/free-solid-svg-icons'


/* Replaces html node with react component
* @param element
* @param reactComponent
*/
export function replaceNodeWithReactComponent(element: HTMLElement, reactComponent: any) {
    const parent = document.createElement('div');
    ReactDOM.render(reactComponent, parent, () => {
        element.replaceWith(...Array.from(parent.childNodes));
    });
}

/**
 * Header provides buttons to change between different sites in the desktop version.
 * When using the mobile version. A dropdown menu and a sidebar is provided.
 */
export default class Header extends Page {

    constructor(props) {
        super(props);
        this.last_size = pixel_width_mobile + 100 // TODO
        this.logo = faCircleHalfStroke;
        this.state = {
            backgroundFunction: props.backgroundFunction,
            show_elements: true,
            side_bar_visible: false,
            logo: faCircleHalfStroke,
            styling: this.props.styling_function,
        }
    }


    animate() {
        this.toggle_single_element("side_bar_selector", "responsive", 'className')
        this.toggle_single_element("header", "set_bg_color", 'className')
        this.toggle_single_element("background_cover", "responsive", 'className')

        this.toggle_single_element("side_bar_stripe1", "responsive", 'id')
        this.toggle_single_element("side_bar_stripe2", "responsive", 'id')
        this.toggle_single_element("side_bar_stripe3", "responsive", 'id')

        this.toggle_single_element("side_bar_div", "responsive_" + this.state.screen_size, 'id')

        if (this.state.screen_size === 'small' || this.state.screen_size === 'mobile') {
            this.setState({show_elements: false})
        } else {
            this.setState({show_elements: true})
        }

        this.setState({'side_bar_visible': !this.state.side_bar_visible})
    }

    sidebar_shift_back() {
        const sideBarSelector = document.getElementsByClassName("side_bar_selector");
        if (sideBarSelector && sideBarSelector.length > 0) {
            if (sideBarSelector[0].classList.value.includes('responsive'))
                this.animate()
        } else
            alert("side_bar_selector not found")
    }

    toggle_dark_bright_style() {
        if (this.state.logo === faMoon)
            this.setState({'logo': faSun});
        else
            this.setState({'logo': faMoon});
    }

    render() {
        return (
            <div id={'header2_' + this.props.styling} className={'header header_' + this.state.screen_size}>
                <div className="headerElements">
                    <div
                        className={"side_bar_selector side_bar_selector_" + this.props.styling
                            + ' side_bar_selector_' + this.state.screen_size}
                        onClick={() => {
                            this.animate()
                        }} onMouseOver={() => {
                        this.animate()
                    }}>
                        <div id='side_bar_stripe1'
                             className={"side_bar_stripe_" + this.props.styling +
                                 ' side_bar_stripe_' + this.state.screen_size}/>
                        <div id="side_bar_stripe2" className={"side_bar_stripe_" + this.props.styling +
                            ' side_bar_stripe_' + this.state.screen_size}/>
                        <div id="side_bar_stripe3" className={"side_bar_stripe_" + this.props.styling +
                            ' side_bar_stripe_' + this.state.screen_size}/>
                    </div>

                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="Home" id="button1" onClick={() => {
                            this.state.backgroundFunction("Home", this.props.styling);
                            this.sidebar_shift_back()
                        }}/>}
                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="About Me" id="button1"
                            onClick={() => {
                                this.state.backgroundFunction("About Me", this.props.styling);
                                this.sidebar_shift_back()
                            }}/>}
                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="Research" id="button1"
                            onClick={() => {
                                this.state.backgroundFunction("Research", this.props.styling);
                                this.sidebar_shift_back()
                            }}/>}
                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="Teaching" id="button1"
                            onClick={() => {
                                this.state.backgroundFunction("Teaching", this.props.styling);
                                this.sidebar_shift_back()
                            }}/>}
                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="Projects" id="button1"
                            onClick={() => {
                                this.state.backgroundFunction("Projects", this.props.styling);
                                this.sidebar_shift_back()
                            }}/>}

                    {(this.state.screen_size !== 'small' && this.state.screen_size !== 'mobile') &&
                        <Button
                            button_style={'button_' + this.props.styling + ' button_header_' + this.state.screen_size}
                            value="Blog" id="button1"
                            onClick={() => {
                                this.state.backgroundFunction("Blog", this.props.styling);
                                this.sidebar_shift_back()
                            }}/>}

                    <FontAwesomeIcon icon={this.state.logo}
                                     className={'change_color_icon change_color_icon_' + this.state.screen_size + ' change_color_icon_' + this.props.styling}
                                     onClick={() => {
                                         this.props.styling_function()
                                         this.toggle_dark_bright_style()
                                     }}/>
                </div>
                <SideBar backgroundFunction={this.state.backgroundFunction} styling={this.props.styling}/>
            </div>
        )
    }
};