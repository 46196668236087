import React from 'react'
import '../../css/SideBar.css'
import Page from "../Page";

export class SideBar extends Page {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef();
        this.state = {
            backgroundFunction: props.backgroundFunction,
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }

    animate() {
        this.toggle_single_element("side_bar_selector", "responsive", 'className')
        this.toggle_single_element("header", "set_bg_color", 'className')
        this.toggle_single_element("background_cover", "responsive", 'className')

        this.toggle_single_element("side_bar_stripe1", "responsive", 'id')
        this.toggle_single_element("side_bar_stripe2", "responsive", 'id')
        this.toggle_single_element("side_bar_stripe3", "responsive", 'id')

        this.toggle_single_element("side_bar_div", "responsive_" + this.state.screen_size, 'id')
        this.setState({'side_bar_visible': !this.state.side_bar_visible})
    }

    render() {
        return (<div ref={this.wrapperRef} className={'side_bar_main_' + this.props.styling} id='side_bar_div'>
                <ul>
                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction('Home', this.props.styling);
                            this.animate()
                        }}>Home
                    </li>
                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction("About Me", this.props.styling);
                            this.animate()
                        }}>About Me
                    </li>
                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction("Research", this.props.styling);
                            this.animate()
                        }}>Research
                    </li>
                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction("Teaching", this.props.styling);
                            this.animate()
                        }}>Teaching
                    </li>
                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction("Projects", this.props.styling);
                            this.animate()
                        }}>Projects
                    </li>

                    <li className={'list_item_side_bar list_item_side_bar_' + this.state.screen_size + '_' +
                        this.state.orientation + ' list_item_side_bar_' + this.props.styling}
                        onClick={() => {
                            this.state.backgroundFunction("Blog", this.props.styling);
                            this.animate()
                        }}>Blog
                    </li>

                </ul>
                <div className="coverAll"/>
            </div>
        )
    }
}

export default SideBar
