import React from 'react'
import '../../css/Footer.css'

import Page from "../Page";
import Button from '../Button';
import Imprint from "../sites/Imprint";

/**
 * Footer shows the copyright, social networks and an imprint butt
 */
export class Footer extends Page {

    /**
     * Sets the correct size of the different div-containers upon mounting this react component.
     */
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.add_single_element('imprint_div', 'invisible', 'className')
        this.remove_single_element('imprint_div', 'visible', 'className')
        this.resize();
    }

    /**
     * Sets the size of this page accordingly, depending on if mobile or desktop version should be shown.
     */
    resize() {
        this.set_screen_size_state();
    }

    /**
     * Set imprint layer visible and scroll to the top.
     */
    show_imprint() {
        this.toggle_single_element('imprint_div', 'animate', 'className')
        this.add_single_element('imprint_div', 'visible', 'className')
        this.remove_single_element('imprint_div', 'invisible', 'className')
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        return (
            <React.Fragment>
                <Imprint value="none" styling={this.props.styling} screen_size={this.state.screen_size}/>
                <div className={"footer footer_"+ this.props.styling + ' footer_'+this.state.screen_size}>
                    <div className={'copyright_box copyright_box_'+this.state.screen_size + ' copyright_box_'+this.props.styling}>
                        &copy; Florian Frank 2024
                    </div>
                    <div className={'logo_box logo_box_'+this.state.screen_size}>
                        <i className={'fab fa-github social_media social_media_'+this.props.styling + ' social_media_'+this.state.screen_size}
                           onClick={() => {
                               window.open("https://github.com/FlorianFrank", '_blank')
                           }}/>
                        <i className={'fab fa-researchgate social_media social_media_'+this.props.styling + ' social_media_'+this.state.screen_size}
                           onClick={() => {
                               window.open("https://www.researchgate.net/profile/Florian-Frank-10", '_blank')
                           }}/>
                        <i className={'fab fa-linkedin social_media social_media_'+this.props.styling + ' social_media_'+this.state.screen_size}
                           onClick={() => {
                               window.open("https://www.linkedin.com/in/florian-frank-1287a21b5/", '_blank')
                           }}/>
                    </div>

                    <Button value='Imprint' button_style={'button_' + this.props.styling + ' button_'+this.state.screen_size} inner_id='footer_button'
                            onClick={() => {
                                this.show_imprint()
                            }}/>
                    <div className="headerElements">
                    </div>
                </div>
            </React.Fragment>
        )
            ;
    }
}

export default Footer;
