export const pixel_width_mobile = [0, 415, 'mobile']; // IPhone 8 and smaller
export const pixel_width_small = [415, 1023, 'small']; // Tablet
//export const pixel_width_medium = [1023, 1919]; // Full HD
//export const pixel_width_large = [1919, 65000]; // > 2K
export const pixel_width_medium = [1023, 2077, 'medium']; // Full HD
export const pixel_width_large = [2077, 65000, 'large']; // > 2K

export const start_page_header_delay = 100;
export const DEFAULT_STYLE = 'dark';

export const START_PAGE_ID     = 0;
export const ABOUT_ME_PAGE_ID  = 1;
export const RESEARCH_PAGE_ID  = 2;
export const TEACHING_PAGE_ID  = 3;
export const PROJECT_PAGE_ID   = 4;
export const BLOG_PAGE_ID   = 5;
