import React, {Fragment} from 'react'
import ProjectElement from "./page_elements/projects/ProjectElement"
import Page from "../Page";

import "../../css/sites/Projects.css"

import pufmem_stock from '../../../assets/pufmem_stock.jpg'
import nanosec_stock from '../../../assets/nanosec_stock.jpg'

//import switch_matrix_figure from '../../../assets/Switch_Matrix.png'
import fpga_figure from '../../../assets/test_bench.jpg'
import haselnuss_picture from '../../../assets/haselnuss_picture.jpg'

/**
 * React component gives an overview of different projects.
 * Each project is described by a ProjectElement typically consisting of a description and a figure.
 */
export class Projects extends Page {

    constructor(props) {
        super(props);
        window.projects_component = this;

        this.state = {
            styling: props.styling,
            mobile: false
        }
    }

    /*
    autotechagil_description(screen_size) {
        return
        "Chips produced in the analogue world have slight variations that make each" +
        " one unique. These differences can be used in computer science to create physically unclonable functions " +
        "(PUFs), acting as digital fingerprints for hardware identification. PUFs are considered a robust and simple " +
        "solution to enhance security, particularly for the Internet of Things. In the NANOSEC project, researchers " +
        "from the University of Passau and TU Chemnitz are collaborating to explore the use of carbon nanotubes in PUFs. " +
        "They are developing hybrid PUFs with sensor functionality, which could be a promising addition to security " +
        "applications such as autonomous driving, providing trustworthy sensor data. The project is funded by the" +
        " German Research Foundation (DFG) for three years."
    }*/

    nanosec_description(screen_size, styling) {
        return (
            <React.Fragment>The Project <b><a className={'a_' + styling} href={'https://gepris.dfg.de/gepris/projekt/439892735'}>NANOSec</a></b> as part of the DFG (Deutsche Forschungsgemeinschaft)
                "Schwerpunktprogramm" <a className={'a_' + styling}
                    href={'https://gepris.dfg.de/gepris/projekt/422730034?context=projekt&task=showDetail&id=422730034&'}>SPP
                    2253:
                    Nano Security: Von Nanoelektronik zu Sicheren Systemen</a> is centered around the exploration of
                novel nanomaterial devices, to be exploited as Physical Unclonable Functions. Such devices include Carbon-NanoTube Field-Effect Transistors (CNT-FETs).
                The project is a collaboration with the Technical University of Chemnitz involving their expertise in wafer production for novel device structures.
                Simultaneously, the University of Passau is responsible for the device analysis and the implementation of a measurement and evaluation platform, facilitating the automated readout of PUFs.
                Furthermore the robustness of the developed PUF construction is tested against invasive and non-invasive attacks.
                As a result, the project aims to develop novel hybrid devices, not only
                serve as advanced transistors in emerging device generations but also integrate intrinsic, highly secure hardware
                fingerprints. Moreover, they can be equipped with additional functionalities, such as the capability to operate as sensors.
                This innovation marks a significant step forward in establishing enhanced security measures,
                particularly in developing fields like autonomous driving.
            </React.Fragment>);
    }

    pufmen_description(screen_size, styling) {
        return (
            <React.Fragment>The goal of project <b><a className={'a_' + styling} href={'https://gepris.dfg.de/gepris/projekt/440182124'}>PUFmem</a></b> (exploring
                intrinsic <b>P</b>hysically <b>U</b>nclonable <b>F</b>unctions
                from new-type non-volatile <b>mem</b>ories), is identify unique fingerprints within novel memory modules, including
                MRAM (Magnetoresistive RAM), FRAM (Ferroelectric RAM) or ReRam (Resistive RAM).
                These distinct fingerprints can be utilized in applications, for example, authentication protocols, device identification or data encryption.
                This project is part of the DFG (Deutsche Forschungsgemeinschaft)
                "Schwerpunktprogramm" <a className={'a_' + styling}
                    href={'https://gepris.dfg.de/gepris/projekt/422730034?context=projekt&task=showDetail&id=422730034&'}>SPP
                    2253:
                    Nano Security: Von Nanoelektronik zu Sicheren Systemen</a>.
                To retrieve PUF behavior from those memories,
                methods employed in other PUF constructions, specifically those designed for DRAM or SRAM memory are adopted. Those methods include,
                for example, row-hammering, intentional violation of the timing specification or supply voltage variations.
                Furthermore, passive circuit elements such as memristors are examined, enabling a better understanding of
                the fundamental components of memories without the encapsulating circuitry found in commercial memory devices.
                The overarching goal of all constructions is to identify consistently reproducible bit-flips, which can
                then be utilized for the previous mentioned applications.
                Additionally, the PUF constructions undergo intensive testing in diverse environmental conditions, including
                variations in temperature. Ultimately, hardware and software architectures are implemented to test the developed
                PUF constructions, demonstrated in applications like data-binding on FPGAs.
                The Project ended by the end of October 2023.</React.Fragment>)
    };

    haselnuss_description(screen_size, styling) {
        return <React.Fragment>
            {screen_size === 'large' || screen_size === 'medium' ?
                <h3 className={'projects_header_' + styling}>Haselnuss Prototype</h3> : ''}
            In this project we presented a IT security concept to protect field elements in railway systems
            utilizing
            the <a className={'a_' + styling}
                   href='https://de.wikipedia.org/wiki/Rail_Safe_Transport_Application'>RaSTA (Rail Safe Transport
            Application)</a> protocol. The implementation is based on an implementation of a former project <a
            className={'a_' + styling}
            href='https://www.haselnuss-projekt.de/'>HASELNUSS
            (Hardwarebasierte Sicherheitsplattform für Eisenbahn-Leit- und Sicherungstechnik)</a>.
            Compared to the prior project's implementation, our current approach relies on the advanced hardware
            technology offered by <a className={'a_' + styling} href='https://www.sysgo.com/'>SYSGO</a>.
            This
            hardware incorporates a sophisticated hypervisor solution capable of managing multiple protected
            partitions
            simultaneously. Our system is organized into three distinctive partitions: a security partition designed
            to
            handle encrypted commands, including those related to signals or points from the signal box application,
            forwarded to a second partition called safety partition, which is responsible for safety
            critical tasks. It deals with edge-cases such as a loss of network connection and provides the required
            error handling routines. The last partition
            called I/O partition is responsible for transforming commands received from the safety partition and
            transforms them into electrical signals controlling the field elements. The project results were
            presented
            in the <a className={'a_' + styling} href={'https://osm.hpi.de/drss/2023/'}>Digital Rail
            Summer School (DRSS)
            2023</a>.
        </React.Fragment>
    }

    dram_spyping_description(screen_size, styling) {
        return <React.Fragment>
            {screen_size === 'large' || screen_size === 'medium' ?
                <h3 className={'projects_header_' + styling}>DRAM retention to
                    spy on ambient temperature</h3> : ''}

            In this project Intel Galileo boards were utilized in order to spy their ambient temperature,
            by exploiting the temperature dependent retention effect of capacitors in DRAM cells. These retention
            effects occur, when
            disabling their DRAM refresh operation, resulting in bit-flips. The amount
            of bit-flips is highly temperature dependent and can be utilized to calculate the ambient temperature of
            the device.
            The experiments were conducted by putting multiple Galileo
            Boards in a climate chamber were different temperatures were tested. The readout was done by a kernel
            module during runtime allowing for temperature spying attacks during runtime. These experiments
            resulting in the
            paper <a className={'a_' + styling} href='https://doi.org/10.1109/TIFS.2023.3271252'>"Abusing
            Commodity DRAMs in IoT Devices
            to Remotely Spy on Temperature"</a>. The code for the kernel module was adopted from a previous work
            by <a className={'a_' + styling} href='https://doi.org/10.23919/DATE.2019.8714882'>Xiong et
            al.</a>, which our paper builds upon.
            The results were obtained by a test framework controlling, both the Galileo Boards, as
            well as the climate chamber, allowing for highly precise and reproducible measurements.
            Finally, we demonstrated two practical attacks in which a malicious remote device
            monitored temperature fluctuations, whether they occurred in the IoT environment or within sensitive
            server rooms.
        </React.Fragment>
    }

    fpga_implementations(screen_size, styling) {
        return <Fragment>
            {screen_size === 'large' || screen_size === 'medium' ?
                <h3 className={'projects_header_' + styling}>FPGA implementations</h3> : ''}

            I'm working in multiple FPGA-based projects including the development of various kinds of modules
            encapsulated in IP-cores.
            These IP-cores encompass the implementation of cryptographic
            ciphers like Speck (<a className={'a_' + styling}
                                   href='https://github.com/FlorianFrank/SPECK_Cipher_Verilog'>Speck cipher</a>),
            modules to interact between CPUs and FPGAs on Xilinx boards to facilitate communication between CPUs and
            FPGAs leveraging different AXI implementations.
            These modules contain FIFO and DMA functionality (<a className={'a_' + styling}
                                                                 href='https://github.com/FlorianFrank/AXI-streaming-interface'>AXI-streaming
            interface</a>).

            Furthermore other IP-cores were implemented, such as a customizable UART IP-core supporting different
            configuration parameters. This IP-core was tested on a Xilinx Spartan-6
            board achieving more then 4 MBaud transfer rate (<a className={'a_' + styling}
                                                                href='https://github.com/FlorianFrank/Verilog-UART-Custom-IP'>Custom
            UART IP-Core</a>).

            Moreover, my current research involves the integration of various IP cores to create comprehensive
            processor-like architectures.
        </Fragment>
    }

    /**
     * Renders the project page.
     * @returns {JSX.Element}
     */
    render() {
        return (
            <div className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={'main_content_div_' + this.state.screen_size}>
                    <h2 className={"heading1_" + this.state.styling}>Projects</h2>
                    <div className={"subdiv_" + this.state.styling}>
                        <table>
                            <tr>
                                <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>PUFMem</th>
                            </tr>
                            <ProjectElement screen_size={this.state.screen_size} styling={this.state.styling}
                                            picture={pufmem_stock} description={this.pufmen_description}/>

                            <tr>
                                <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>NanoSec</th>
                            </tr>
                            <ProjectElement screen_size={this.state.screen_size} styling={this.state.styling}
                                            picture={nanosec_stock} description={this.nanosec_description}/>

                            <tr>
                                <th className={'heading2 heading2_' + this.state.styling} colSpan='2'>Other Projects</th>
                            </tr>

                            {this.state.screen_size === 'mobile' || this.state.screen_size === 'small' ?
                                <h3 className={'projects_header_' + this.state.styling}>DRAM retention to
                                    spy on ambient temperature</h3> : ''}
                            <ProjectElement picture={""} screen_size={this.state.screen_size}
                                            styling={this.state.styling}
                                            description={this.dram_spyping_description}/>

                            {this.state.screen_size === 'mobile' || this.state.screen_size === 'small' ?
                                <h3 className={'projects_header_' + this.state.styling}>Haselnuss Prototype</h3> : ''}
                            <ProjectElement picture={haselnuss_picture} screen_size={this.state.screen_size}
                                            styling={this.state.styling}
                                            description={this.haselnuss_description}/>

                            {this.state.screen_size === 'mobile' || this.state.screen_size === 'small' ?
                                <h3 className={'projects_header_' + this.state.styling}>FPGA implementations</h3> : ''}
                            <ProjectElement screen_size={this.state.screen_size} styling={this.state.styling}
                                            picture={fpga_figure}
                                            description={this.fpga_implementations}/>

                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

/*<ProjectElement screen_size={this.state.screen_size} styling={this.state.styling}
                picture="./assets/Instrument_Control_Lib.svg"
                description={this.instrument_control_lib_description}/>
<ProjectElement screen_size={this.state.screen_size} styling={this.state.styling}
                picture={switch_matrix_figure}
                description={this.switch_matrix_description}/>q*/

export default Projects
