import React from "react";
import "../../../../css/Main.css"
import "../../../../css/sites/AboutMe.css"


class CVTable extends React.Component {
    get generate_table() {
        return this.props.cv_elements.map((item) =>
            <tr className='tr_cv_content'>
                <td className={'default_text_div_' + this.props.screen_size + ' title_row_'+ this.props.styling}>
                    {item.title}
                </td>
                <td className={'default_text_div_' + this.props.screen_size + ' date_row data_row_' + this.props.styling}>
                    {item.start === "" && "" + item.end + ""}
                    {item.end === "" && item.start + " - "}
                    {item.start !== "" && item.end !== "" && "" + item.start + " - " + item.end}
                </td>
            </tr>
        );
    }

    render() {
        return <React.Fragment>
            {this.generate_table}
        </React.Fragment>;
    }
}

export default CVTable