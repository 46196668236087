import {Component} from 'react'

import {pixel_width_large, pixel_width_medium, pixel_width_mobile, pixel_width_small} from "../../definitions";

import "../css/Main.css"
import profile_picture from "../../assets/ProfilePicture.jpg";


/**
 * Start-page component shows an image slider and gives a small introduction to my person.
 */
export class Page extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            screen_size: 'medium',
            orientation: 'horizontal',
            styling: 'dark',
            content: "<React.Fragment/>"
        }
    }

    /**
     * Set Style of this React component either to dark or bright mode.
     * @param style
     */
    setStyle(style) {
        this.setState({styling: style})
    }

    componentDidMount() {
        window.addEventListener("resize", this.set_screen_size_state.bind(this));
        this.set_screen_size_state();
    }

    apply_action_single_element(identifier, action, identifierType, operation) {
        let element = null;
        if (identifierType === 'className') {
            const elementClassList = document.getElementsByClassName(identifier)
            if (elementClassList && elementClassList.length > 0) {
                element = elementClassList[0].classList
            }

        } else if ('id') {
            const elementId = document.getElementById(identifier)
            if (elementId)
                element = elementId.classList
        } else
            alert("Error undefined identifier type: " + identifierType)

        if (element) {
            if (operation === 'add')
                element.add(action)
            if (operation === 'remove')
                element.remove(action)
            if (operation === 'toggle')
                element.toggle(action)
        } else
            alert("Error: " + identifier + " not defined")
    }

    set_screen_size_state() {

        if(window.innerWidth > window.innerHeight)
            this.setState({'orientation': 'horizontal'})
        else
            this.setState({'orientation': 'vertical'})

        let pixel_width = 0

        var innerWidth = window.innerWidth
        console.log("WINDOW WIDTH " + innerWidth)

        if (innerWidth > pixel_width_large[0]) {
            pixel_width = pixel_width_large[2];
        } else if (innerWidth <= pixel_width_medium[1] && innerWidth > pixel_width_medium[0]) {
            pixel_width = pixel_width_medium[2];
        } else if (innerWidth <= pixel_width_small[1] && innerWidth > pixel_width_small[0]) {
            pixel_width = pixel_width_small[2];
        } else if (innerWidth <= pixel_width_mobile[1])
            pixel_width = pixel_width_mobile[2];

        this.setState({'screen_size': pixel_width, 'style': this.state.style})
        console.log("Set window width " + this.state.screen_size);
        return pixel_width
    }

    toggle_single_element(identifier, action, identifierType) {
        this.apply_action_single_element(identifier, action, identifierType, 'toggle')
    }

    add_single_element(identifier, action, identifierType) {
        this.apply_action_single_element(identifier, action, identifierType, 'add')
    }

    remove_single_element(identifier, action, identifierType) {
        this.apply_action_single_element(identifier, action, identifierType, 'remove')
    }

    render_content(content) {
        this.setState({'content': content})
        this.render()
    }

    render() {
        return (
            <div className={"master_div master_div_" + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={'main_content_div main_content_div_' + this.state.screen_size}>
                    <img className="profile_picture" src={profile_picture} alt=""/>
                    <div className={"subdiv_" + this.state.styling}>
                        {this.state.content}
                    </div>
                </div>
            </div>);
    }
}

export default Page;
