import React from "react";
import Collapsible from "../../../Collapsible";

import '../../../../css/sites/Research.css'


class ResearchItem extends React.Component {
    render() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan='2' className='publications_table_row'>
                        <Collapsible
                            closed
                            title={this.props.title}
                            styling={this.props.styling}
                            screen_size={this.props.screen_size}
                        >
                            <table
                                className={"table_publication_info table_publication_info_" + this.props.screen_size + " table_publication_info_" + this.props.styling}>
                                <tr>
                                    <td className={"publication_info_item_" + this.props.screen_size}>Authors:</td>
                                    <td className={"publication_info_item_" + this.props.screen_size}>{this.props.authors}</td>
                                </tr>
                                {(this.props.journal) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Journal:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.journal}</td>
                                    </tr> : ''}
                                {(this.props.volume) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Volume:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.volume}</td>
                                    </tr> : ''}
                                {(this.props.number) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Number:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.number}</td>
                                    </tr> : ''}
                                {(this.props.article_number) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Article Number:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.article_number}</td>
                                    </tr> : ''}
                                {(this.props.issn) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>ISSN:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.issn}</td>
                                    </tr> : ''}
                                {(this.props.venue) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Conference:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.venue}</td>
                                    </tr> : ''}
                                {(this.props.booktitle) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Book Title:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.booktitle}</td>
                                    </tr> : ''}
                                {(this.props.publisher) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Publisher:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.publisher}</td>
                                    </tr> : ''}
                                {(this.props.venue_location) ? <tr>
                                    <td className={"publication_info_item_" + this.props.screen_size}>Venue Location:</td>
                                    <td className={"publication_info_item_" + this.props.screen_size}>{this.props.venue_location}</td>
                                </tr> : ''}

                                {(this.props.pages) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>Pages:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}>{this.props.pages}</td>
                                    </tr> : ''}
                                {(this.props.doi) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>DOI:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}><a
                                            className={'a_' + this.props.styling}
                                            href={this.props.doi}>{this.props.doi.replace('https://doi.org/', '')}</a>
                                        </td>
                                    </tr> : ''}
                                {(this.props.file) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>File:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}><a
                                            className={'a_' + this.props.styling}
                                            href={this.props.file}>{this.props.file_name}</a>
                                        </td>
                                    </tr> : ''}
                                {(this.props.bibtex) ?
                                    <tr>
                                        <td className={"publication_info_item_" + this.props.screen_size}>BibTeX:</td>
                                        <td className={"publication_info_item_" + this.props.screen_size}><a
                                            className={'a_' + this.props.styling}
                                            href={this.props.bibtex}>Download Reference</a>
                                        </td>
                                    </tr> : ''}
                            </table>
                        </Collapsible>
                    </td>
                </tr>
            </React.Fragment>)
            ;
    }
}

export default ResearchItem

