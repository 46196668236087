import './App.css';
import './components/css/Header.css'
import {
    ABOUT_ME_PAGE_ID, BLOG_PAGE_ID,
    DEFAULT_STYLE,
    PROJECT_PAGE_ID,
    RESEARCH_PAGE_ID,
    START_PAGE_ID,
    TEACHING_PAGE_ID
} from "./definitions";

import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';


import Header from "./components/jsx/header_footer/Header";
import Background from './components/jsx/Background';
import Footer from './components/jsx/header_footer/Footer';

import AboutMe from './components/jsx/sites/AboutMe';
import StartPage from './components/jsx/sites/StartPage';
import Projects from './components/jsx/sites/Projects';
import Research from './components/jsx/sites/Research';
import Teaching from "./components/jsx/sites/Teaching";
import Blog from "./components/jsx/sites/Blog";

const inner_width = "1px";


function App() {

    const [mode, setMode] = useState(DEFAULT_STYLE)
    const [main_component, set_main_component] = useState([TEACHING_PAGE_ID, <Teaching styling={mode}/>])

    useEffect(() => {
        if (window.location.pathname === '/aboutme')
            set_main_component([ABOUT_ME_PAGE_ID, <AboutMe styling={mode}/>])
        else if (window.location.pathname === '/teaching')
            set_main_component([TEACHING_PAGE_ID, <Teaching styling={mode}/>])
        else if (window.location.pathname === '/research')
            set_main_component([RESEARCH_PAGE_ID, <Research styling={mode}/>])
        else if (window.location.pathname === '/blog')
            set_main_component([BLOG_PAGE_ID, <Blog styling={mode}/>])
        else if (window.location.pathname === '/projects')
            set_main_component([PROJECT_PAGE_ID, <Projects styling={mode}/>])
        else if (window.location.pathname === '/')
            set_main_component([START_PAGE_ID, <StartPage styling={mode}/>])

    }, [mode],);


    useEffect(() => {
        window.bg_component.change_content(main_component[1]);
    }, [main_component]);

    useEffect(() => {
        switch (main_component[0]) {
            case START_PAGE_ID:
                window.start_page.setStyle(mode);
                break;
            case ABOUT_ME_PAGE_ID:
                window.about_me_component.setStyle(mode)
                break
            case RESEARCH_PAGE_ID:
                window.research.setStyle(mode)
                break;
            case TEACHING_PAGE_ID:
                window.teaching_component.setStyle(mode);
                break;
            case PROJECT_PAGE_ID:
                window.projects_component.setStyle(mode)
                break;
            case BLOG_PAGE_ID:
                window.blog_component.setStyle(mode)
                break;
            default:
                console.log("Error unsupported main component id");
                break;
        }
    }, [mode])

    /**
     * Sets the styling parameter in a global variable. Currently dark and bright mode are supported.
     */
    function set_styling() {
        if (mode === 'dark')
            setMode('bright')
        else
            setMode('dark')
    }

    /**
     * Function dynamically replaces react components depending on the currently selected page.
     * @param selected_page string of the page being selected.
     * @param style the style which is applied to this component, currently either dark or bright mode.
     */
    function change_background(selected_page, style) {
        switch (selected_page) {
            case "Home":
                navigate('/home')
                set_main_component([START_PAGE_ID, <StartPage styling={style}/>]);
                break;
            case "About Me":
                navigate('/aboutme')
                set_main_component([ABOUT_ME_PAGE_ID, <AboutMe styling={style}/>]);
                break;
            case "Research":
                navigate('/research')
                set_main_component([RESEARCH_PAGE_ID, <Research styling={style}/>]);
                break;
            case "Teaching":
                navigate('/teaching')
                set_main_component([TEACHING_PAGE_ID, <Teaching styling={style}/>]);
                break;
            case "Projects":
                navigate('/projects')
                set_main_component([PROJECT_PAGE_ID, <Projects styling={style}/>]);
                break;
            case "Blog":
                navigate('/blog')
                set_main_component([BLOG_PAGE_ID, <Blog styling={style}/>]);
                break;
            default:
                console.log("change_background() reported unsupported React component");
                break
        }
    }
    const navigate = useNavigate();

    /**
     * Renders the application consisting of a header, footer such as an exchangeable React center component.
     */
    return (
        <div className="App" height={inner_width}>
            <div className='content'>
                <Header styling={mode} styling_function={set_styling} backgroundFunction={change_background}
                        component_style={{}}/>
                <Background styling={mode} header1="MAIN HEADER" header2="SECOND HEADER" content={main_component[1]}/>
                <Footer styling={mode}/>
            </div>
        </div>

    );
}

export default App;
