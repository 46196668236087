import React from "react";

import BlogArticle from "./BlogArticle";
import Button from "../../../Button";

import "../../../../css/Main.css"
import "../../../../css/sites/BlogArticle.css"
import "../../../../css/Button.css"


class BlogArticlePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibility_toggle: 2
        }
    }

    open_article() {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (this.state.visibility_toggle === 2)
            this.setState({visibility_toggle: 1})
        else
            this.setState({
                visibility_toggle:
                    (this.state.visibility_toggle === 1) ? 0 : 1
            });
    }

    fragment_desktop() {
        return <div className={'blog_element_div blog_element_div_' + this.props.screen_size}
                    id={'blog_preview_card_' + this.props.styling}>
            <div className='blog_element_description'>
                <table className='blog_article_preview_table'>
                    <tr>
                        <td className='blog_card_td1'>
                            <div className={'name_circle name_circle_' + this.props.screen_size}>
                                <div className={'text_name_circle text_name_circle_' + this.props.screen_size}>FF</div>
                            </div>
                            <div className={'category_div category_div_' + this.props.screen_size}
                                 id={'blog_heading_' + this.props.styling}>{this.props.category}</div>
                        </td>
                    </tr>
                    <tr>
                        <td className='blog_card_td1'>
                            <div className='article_title_preview' id={'blog_heading_' + this.props.styling}
                                 onClick={() => {
                                     this.open_article()
                                 }}>{this.props.title}</div>
                        </td>
                        <td rowSpan={4} className='blog_card_td2'>
                            <img className={'blog_figure blog_figure_' + this.props.screen_size}
                                 alt='not available' src={this.props.picture_url} onClick={() => {
                                this.open_article()
                            }}/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='blog_card_td1'>
                            <div className={'short_description_' + this.props.screen_size + ' default_text_div_' + this.props.screen_size}
                                 id={'blog_heading_' + this.props.styling}>
                                {this.props.short_description}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='blog_card_td1'>
                            <div className='hashtags'
                                 id={'blog_date_hashtags_' + this.props.styling}>{this.props.hashtags}</div>
                        </td>
                    </tr>
                    <tr>
                        <td className='blog_card_td1'>
                            <div className='date'
                                 id={'blog_date_hashtags_' + this.props.styling}>{this.props.date}</div>
                        </td>
                    </tr>
                    <tr>
                        <td className='blog_card_td1'>
                            <div className={'read_more_button_div_' + this.props.screen_size}>
                                <Button id='read_more_button' value='read more'
                                        button_style={'button_' + this.props.styling + ' button_' + this.props.screen_size}
                                        onClick={() => {
                                            this.open_article()
                                        }
                                        }/>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    }

    fragment_mobile() {
        return <div className='blog_element_div_mobile' id={'blog_preview_card_' + this.props.styling}>
            <div className='blog_element_description_mobile'>
                <table>
                    <tr>
                        <td>
                            <div className={'name_circle name_circle_' + this.props.screen_size}>
                                <div className={'text_name_circle text_name_circle_' + this.props.screen_size}>FF</div>
                            </div>
                            <div className={'category_div category_div_' + this.props.screen_size}
                                 id={'blog_heading_' + this.props.styling}>{this.props.category}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img className={'blog_figure blog_figure_' + this.props.screen_size} alt='not available'
                                 src={this.props.picture_url} onClick={() => {
                                this.open_article()
                            }}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='article_title_preview_mobile' id={'blog_heading_' + this.props.styling}
                                 onClick={() => {
                                     this.open_article()
                                 }}>{this.props.title}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className='short_description_mobile' id={'blog_heading_' + this.props.styling}>
                                {this.props.short_description}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='hashtags_mobile'
                                 id={'blog_date_hashtags_' + this.props.styling}>{this.props.hashtags}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='date_mobile'
                                 id={'blog_date_hashtags_' + this.props.styling}>{this.props.date}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Button inner_id='read_more_button_mobile' value='read more'
                                    button_style={'button_' + this.props.styling + ' button_' + this.props.screen_size}
                                    onClick={() => {
                                        this.open_article()
                                    }
                                    }/>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    }

    render() {

        return (
            <React.Fragment>
                {(this.props.screen_size === 'mobile' ||
                    this.props.screen_size === 'small') ? this.fragment_mobile() : this.fragment_desktop()}
                <BlogArticle className='article_div'
                             offset={0}
                             mobile={true}
                             markdown={this.props.markdown}
                             styling={this.props.styling}
                             screen_size={this.props.screen_size}
                             outer_cover_floating={this.props.outer_cover_floating}
                             hashtagls={this.props.hashtags}
                             title={this.props.title}
                             category={this.props.category}
                             picture_url={this.props.picture_url}
                             innerConent={this.props.innerConnent}
                             structure={this.props.structure}
                             visible={this.state.visibility_toggle}/>
            </React.Fragment>);
    }
}


export default BlogArticlePreview

