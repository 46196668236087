import React from "react";

import CloseButton from "../../../CloseButton";
import MarkdownLoader from "./MarkdownLoader";

import "../../../../css/Main.css"
import "../../../../css/sites/BlogArticle.css"


class BlogArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibility: false
        }
    }


    componentDidUpdate(prevProps, prevState) {
        // do something
        //
        if (this.props.visible !== 2 && prevProps.visible !== this.props.visible && !this.state.visibility) {
            this.setState({'visibility': true})
        }
    }

    render() {

        return (
            <React.Fragment>
                {(this.state.visibility) ?
                    <div className={'outer_cover outer_cover_'+this.props.screen_size + ' ' + (this.props.outer_cover_floating ? '': 'outer_cover_bottom_0')}>
                        <div
                            className={'main_blog_article_' + this.props.screen_size + ' main_blog_article_' + this.props.styling}
                            id={(this.props.screen_size === 'mobile' || this.props.screen_size === 'small') ?
                                'main_div_blog_article_mobile' : 'main_div_blog_article'}
                            style={{
                                visibility: (this.state.visibility) ? 'visible' : 'hidden',
                                'margin-top': this.props.offset + 'vh'
                            }}>

                            <div className={'close_button_div_article_' + this.props.screen_size} onClick={
                                () => {
                                    this.setState({'visibility': false})
                                }
                            }>
                                <CloseButton className='close_button_article' styling={this.props.styling}/>
                            </div>

                            <div className='blog_header_div'>

                                <table className='blog_article_structure_table'>
                                    <tr>
                                        <td>
                                            <div id={'blog_heading_article_' + this.props.styling}
                                                 className={this.props.mobile ? 'article_title_mobile' : 'article_title'}>{this.props.title}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='blog_category_div'>
                                                <div className='hashtags'>{this.props.hashtags}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <div
                                            className={(this.props.screen_size === 'mobile' || this.props.screen_size === 'small') ?
                                                'blog_article_content_div_mobile default_text_div_' + this.props.screen_size : 'blog_article_content_div default_text_div_' + this.props.screen_size}>
                                            {this.props.markdown ? <MarkdownLoader visible={this.state.visibility}
                                                                                   input_file={this.props.innerConent}/> : this.props.innerConent}
                                        </div>

                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div> : ''}
            </React.Fragment>);
    }
}

export default BlogArticle

