import {start_page_header_delay} from '../../../definitions'

import "../../css/sites/StartPage.css"

import Slider from "../image_slider/widgets/Slider/Slider";
import Introduction from "./page_elements/Introduction";
import Page from "../Page";

/**
 * Start-page component shows an image slider and gives a small introduction to my person.
 */
export class StartPage extends Page {

    constructor(props) {
        super(props);
        window.start_page = this;

        this.state = {
            styling: props.styling,
            header_shown: false
        }
    }

    /**
     * When component is mounted, animate the "welcome to my personal website" header.
     */
    componentDidMount() {
        super.componentDidMount()
            this.setState({header_shown: true})
            setTimeout(() => this.showStartHeaderAnimation(), start_page_header_delay)
            document.getElementsByClassName("welcome_div")[0].classList.add("animate");
    }


    /**
     * Let "welcome to my personal website" header, slowly occur.
     * */
    showStartHeaderAnimation() {
        document.getElementsByClassName("welcome_div")[0].classList.add("animate");

    }

    /**
     * Renders all html components of the start page.
     * @returns {JSX.Element}
     */
    render() {
        return (
            <div className={'master_div master_div_' + this.state.screen_size + ' master_div_' + this.state.styling}>
                <div className={"slider_div_" + this.state.screen_size}>
                    <div className={"welcome_div welcome_div_" + this.state.screen_size + ((this.state.header_shown) ? ' welcome_div_visible' : ' welcome_div_invisible')}>
                        <h1 className={"welcome_header welcome_header_"+ this.state.screen_size}>Welcome on my Personal Homepage</h1>
                    </div>
                    <Slider autoPlay={true}/>
                </div>
                <div className={"subdiv subdiv_" + this.state.styling + " subdiv_" + this.state.screen_size}>
                    <Introduction screen_size={this.state.screen_size} orientation={this.state.orientation} styling={this.state.styling}/>
                </div>
            </div>
        )
    }
}

export default StartPage;
