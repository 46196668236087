import React from "react";

import "../../../../../definitions"
import "../../../../css/sites/Projects.css"
import {pixel_width_mobile, pixel_width_small} from "../../../../../definitions";


class ProjectElement extends React.Component {

    render_mobile_view() {
        return (
            <React.Fragment>
                <tr>
                    <td className='project_figure_td'>
                        <img src={this.props.picture} className='project_picture' alt=''/>
                    </td>
                </tr>
                <tr>
                    <td className={'normal_text_' + this.props.styling +
                        ' default_text_div_' + this.props.screen_size}>
                        {this.props.description(this.props.screen_size, this.props.styling)}
                    </td>
                </tr>
            </React.Fragment>);
    }

    render_desktop_view() {
        return (
            <React.Fragment>
                <tr>
                    <td className='project_figure_td'>
                        <img src={this.props.picture} className='project_picture' alt=''/>
                    </td>
                    <td className={'normal_text_' + this.props.styling + ' project_description_' + this.props.screen_size +
                        ' default_text_div_' + this.props.screen_size}>
                        {this.props.description(this.props.screen_size, this.props.styling)}
                    </td>
                </tr>
            </React.Fragment>);
    }


    render() {
        if (this.props.screen_size === pixel_width_mobile[2] || this.props.screen_size === pixel_width_small[2]) {
            return this.render_mobile_view()
        } else {
            return this.render_desktop_view()
        }
    }
}

export default ProjectElement

