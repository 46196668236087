import React, {Component} from 'react'
import '../css/Button.css'


export class CloseButton extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                    <div className={'react_close_button_outer_circle react_close_button_outer_circle_'+this.props.styling}
                         onMouseEnter={() => {
                             for(let i = 0; i < document.getElementsByClassName("react_close_button_outer_circle_" + this.props.styling).length; i++){
                                 document.getElementsByClassName("react_close_button_outer_circle_" + this.props.styling)[i].classList.add('trigger_hover')
                                 document.getElementsByClassName("react_close_button_inner_" + this.props.styling)[i].classList.add('trigger_hover')
                                 document.getElementsByClassName("react_close_button_inner2_" + this.props.styling)[i].classList.add('trigger_hover')
                             }
                         }}

                         onMouseLeave={() => {
                             for(let i = 0; i < document.getElementsByClassName("react_close_button_outer_circle_" + this.props.styling).length; i++) {
                                 document.getElementsByClassName("react_close_button_outer_circle_" + this.props.styling)[i].classList.remove('trigger_hover')
                                 document.getElementsByClassName("react_close_button_inner_" + this.props.styling)[i].classList.remove('trigger_hover')
                                 document.getElementsByClassName("react_close_button_inner2_" + this.props.styling)[i].classList.remove('trigger_hover')
                             }
                         }}
                    >
                        <div className={'react_close_button_inner_x1 react_close_button_inner_'+this.props.styling}/>
                        <div className={'react_close_button_inner_x2 react_close_button_inner2_'+this.props.styling}/>
                    </div>
            </React.Fragment>
        );
    }

}

export default CloseButton;
